import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Layout,
  Button,
  Avatar,
} from 'components/sdk';
import {
  OptInModal,
  PoweredBy,
  UserDrawer,
} from 'components';
import {
  Col,
  Divider,
  Row,
  Tooltip,
} from 'antd';
import {
  BackwardOutlined,
  HomeOutlined,
  HomeFilled,
  DollarCircleOutlined,
  DollarCircleFilled,
  UserOutlined,
  TeamOutlined,
  ApiOutlined,
  ApiFilled,
  ShopOutlined,
  ShopFilled,
  LogoutOutlined,
  MenuOutlined,
  CreditCardOutlined,
  CreditCardFilled,
} from '@ant-design/icons';
import { useIdleTimer } from 'react-idle-timer';
import { Images } from 'assets';
import { usePartnerActionsContext } from 'contexts/PartnerActionsContext';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import useWindowDimensions from 'utils/hooks/useWindowDimensions';
import { isBrightColor } from 'utils/colors';
import { badescPortfolios, badesulPortfolios } from 'utils';
import {
  isVideoMotivationalSeen, meVar, menuCollapsedVar, setMenuCollapsedVar,
} from 'services/graphql/cache';
import {
  useMutation,
  useReactiveVar,
} from '@apollo/client';
import {
  LOG_OUT,
  REMOVE_SESSION,
} from './graphql';

import './styles.less';

const {
  LogoGyraDark,
} = Images;
const { Sider: GyraSider } = Layout;

const menuItems = [{
  id: 'pagina-inicial-menu-button',
  icon: <HomeOutlined />,
  activeIcon: <HomeFilled />,
  label: 'Página Inicial',
  key: '/',
  pathToNotShow: ['/cadastro', '/propostas'],
}, {
  id: 'emprestimos-menu-button',
  icon: <DollarCircleOutlined />,
  activeIcon: <DollarCircleFilled />,
  label: 'Empréstimos',
  key: '/emprestimos',
  statusToShow: ['new', 'new-lead'],
  pathToNotShow: ['/cadastro', '/propostas'],
}, {
  id: 'antecipacao-menu-button',
  icon: <CreditCardOutlined />,
  activeIcon: <CreditCardFilled />,
  label: 'Antecipação',
  key: '/antecipacao',
  pathToNotShow: ['/propostas'],
}, {
  divider: true,
  pathToNotShow: ['/propostas'],
}, {
  id: 'perfil-menu-button',
  icon: <UserOutlined />,
  label: 'Perfil',
  key: '/perfil',
  pathToNotShow: ['/propostas'],
}, {
  id: 'responsaveis-menu-button',
  icon: <TeamOutlined />,
  label: 'Responsáveis',
  key: '/gerenciar-responsaveis',
  statusToShow: ['awaiting-signature'],
  rolesToShow: ['owner'],
  pathToNotShow: ['/propostas'],
}, {
  id: 'plataformas-cadastradas-menu-button',
  icon: <ApiOutlined />,
  activeIcon: <ApiFilled />,
  label: 'Contas',
  key: '/plataformas-cadastradas',
  pathToNotShow: ['/propostas'],
}, {
  id: 'propostas-menu-button',
  icon: <ShopOutlined />,
  activeIcon: <ShopFilled />,
  label: 'Trocar de empresa',
  key: '/propostas',
  pathToNotShow: ['/propostas'],
}, {
  pathToNotShow: ['/propostas'],
  divider: true,
}, {
  icon: <LogoutOutlined />,
  label: 'Sair',
  key: '/sair',
}];

const Sider = () => {
  const history = useHistory();
  const location = useLocation();
  const menuCollapsed = useReactiveVar(menuCollapsedVar);
  const validationModalSeen = useReactiveVar(isVideoMotivationalSeen);
  const { width } = useWindowDimensions();

  const { checkActions, loadingActions } = usePartnerActionsContext();
  const {
    me,
    currentContext,
    contextLoading,
    setIsBusinessRegistrationCompleted,
    isBusinessRegistrationCompleted,
  } = useBusinessLoanContext();

  const [expanded, setExpanded] = useState(!menuCollapsed);
  const [activeKey, setActiveKey] = useState(location.pathname);
  // const [userDrawerVisible, setUserDrawerVisible] = useState(false);
  const [optInModalVisible, setOptInModalVisible] = useState(false);

  const portfolioName = currentContext?.loan?.portfolioName;
  const status = useMemo(() => currentContext?.loan?.status, [currentContext]);
  const partnerLogo = useMemo(() => {
    if (!currentContext?.partner?.appColors?.primary) {
      return null;
    }

    if (isBrightColor(currentContext?.partner?.appColors?.primary)) {
      return currentContext.partner.darkLogo;
    }
    return currentContext.partner.lightLogo;

  }, [currentContext]);

  const {
    fullName,
    optIns = [],
    roles = [],
    currentBusiness,
  } = me || {};

  const [removeSession] = useMutation(REMOVE_SESSION, {
    onCompleted: () => window.location.reload(),
  });
  const [logOut] = useMutation(LOG_OUT, {
    onCompleted: () => {
      removeSession();
      localStorage.removeItem('selectBusinessModalSeen');
      localStorage.removeItem('verificationCodeSent');
    },
  });

  useEffect(() => {
    if (contextLoading) {
      return;
    }

    const ownerRole = roles.find((role) => role.name.includes(`owner-${currentBusiness.id}`));

    if (ownerRole
      && ((currentBusiness.employeesNumber === undefined
        || currentBusiness.employeesNumber === null)
        || (currentBusiness.revenueAmount === undefined
          || currentBusiness.revenueAmount === null)
        || !currentBusiness.sector
        || !currentBusiness.segment
        || !currentBusiness.platformType
        || !me.fullName
        || !me.email
        || !me.cpf
        || !me.phoneNumber)) {
      setIsBusinessRegistrationCompleted(false);
    }

    const {
      policyOptIn,
      registryOptIn,
      wppOptIn,
    } = optIns.reduce((acc, { type }) => {
      const result = { ...acc };
      if (type === 'TERMS-AND-POLICIES') {
        result.policyOptIn = true;
      }
      if (type === 'RECEIVABLES-REGISTRY') {
        result.registryOptIn = true;
      }
      if (type === 'WHATSAPP') {
        result.wppOptIn = true;
      }

      return result;
    }, {});

    if (!policyOptIn || !registryOptIn || !wppOptIn) {
      setOptInModalVisible(true);
    }
  }, [currentContext, me]);

  const ableToOpenOptInModal = () => {
    if (loadingActions) {
      return false;
    }

    if (checkActions(['only-score'])) {
      return false;
    }

    if (location.pathname === '/cadastro/integracoes') {
      return validationModalSeen && optInModalVisible;
    }

    return optInModalVisible;
  };

  const handleOptinModal = () => {
    if (!ableToOpenOptInModal() || contextLoading) {
      return null;
    }

    return (
      <OptInModal
        visible
        onClose={() => setOptInModalVisible(false)}
        optIns={optIns}
      />
    );

  };

  useEffect(() => {
    if (menuCollapsed === null && width > 768) {
      setMenuCollapsedVar(false);
      setExpanded(true);
    }
    if (menuCollapsed === null && width <= 768) {
      setMenuCollapsedVar(true);
      setExpanded(false);
    }

    if (menuCollapsed) {
      setExpanded(false);
    } else {
      setTimeout(() => setExpanded(true), 200);
    }
  }, [menuCollapsed]);
  useEffect(() => setActiveKey(location.pathname), [location.pathname]);

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: () => {
      if (meVar()) logOut();
    },
    debounce: 500,
  });

  if (!meVar()) {
    return false;
  }

  const menuItemsToRender = menuItems
    .filter(({ rolesToShow }) => !rolesToShow || rolesToShow.find((role) => roles.find(({ name }) => name === `${role}-${currentBusiness.id}`)))
    .filter(({ statusToShow }) => !statusToShow || statusToShow.includes(status?.value))
    .filter(({ pathToNotShow }) => !pathToNotShow?.find((path) => location.pathname.includes(path)))
    .filter(({ statusToNotShow }) => !statusToNotShow || statusToNotShow.includes(status?.value))
    .filter(({ label }) => {
      if ([...badescPortfolios, ...badesulPortfolios]?.includes(portfolioName)) {
        return !['Contas', 'Antecipação'].includes(label);
      }

      return true;
    });

  return (
    <div>
      <GyraSider className={`menu-sider ${width <= 768 && menuCollapsed ? 'hidden' : ''}`} width={!menuCollapsed ? 242 : 76}>
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <Row align="middle" justify={menuCollapsed ? 'center' : 'space-between'}>
              {!menuCollapsed && (
                <Col>
                  {!contextLoading && expanded && (
                    <>
                      {partnerLogo ? (
                        <div className="partner-logo-container">
                          <img height={24} src={partnerLogo.url} alt="Logo Parceiro" />
                          <PoweredBy className="header-powered-by" />
                        </div>
                      ) : (
                        <img height={24} src={LogoGyraDark} alt="Logo Gyra" />
                      )}
                    </>
                  )}
                </Col>
              )}
              <Col>
                <Button
                  action
                  icon={(<BackwardOutlined className={`menu-sider-button-icon ${menuCollapsed ? 'expand-icon' : 'collapse-icon'}`} />)}
                  onClick={() => setMenuCollapsedVar(!menuCollapsed)}
                />
              </Col>
            </Row>
          </Col>
          <br />
          <br />

          <Col span={24} className="menu-sider-container">
            <Row gutter={[0, 8]}>
              {!contextLoading && menuItemsToRender
                .map(({
                  id,
                  key,
                  icon,
                  activeIcon,
                  label,
                  divider,
                }) => (
                  <Col key={key} span={24}>
                    <Tooltip title={width > 768 && menuCollapsed && label} placement="right">
                      {divider ? (
                        <Divider className="menu-sider-divider" />
                      ) : (
                        <div
                          id={id}
                          className={`menu-item-container ${activeKey === key ? 'active' : ''}`}
                          tabIndex={-1}
                          role="button"
                          onKeyDown={() => false}
                          onClick={() => {
                            if (width <= 768) {
                              setMenuCollapsedVar(true);
                            }

                            if (key === '/sair') {
                              localStorage.removeItem('currentLoan');
                              logOut();

                              return;
                            }

                            history.push(key);
                          }}
                        >
                          <div className="menu-item-icon-container">
                            {activeIcon && (activeKey === key) ? activeIcon : icon}
                          </div>

                          {expanded && (
                            <b className="menu-item-label">
                              {label}
                            </b>
                          )}
                        </div>
                      )}
                    </Tooltip>
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>

        {meVar() && (
          <Row className="menu-sider-user-container" align="middle" justify={expanded ? 'start' : 'center'}>
            <Col span={!menuCollapsed ? 5 : 24} align={!menuCollapsed ? 'left' : 'middle'}>
              <Avatar className="user-avatar" icon={fullName?.[0] || '-'} />
            </Col>
            <Col span={!menuCollapsed ? 19 : 0}>
              {expanded && (
                <div className="user-infos">
                  <div className="user-name">
                    {fullName || 'USUÁRIO SEM NOME'}
                  </div>

                  <div className="user-business-name">
                    <span className="gray-text">
                      {currentBusiness?.name || currentBusiness?.legalName || 'EMPRESA SEM NOME'}
                    </span>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        )}
      </GyraSider>

      {width <= 768 && (
        <>
          <button
            className={`menu-sider-mask ${menuCollapsed ? 'hidden' : ''}`}
            type="button"
            tabIndex={-1}
            onClick={() => setMenuCollapsedVar(true)}
            aria-label="test"
          />

          <Button
            className="menu-sider-mobile-button"
            icon={(<MenuOutlined className="menu-sider-button-icon" />)}
            onClick={() => setMenuCollapsedVar(false)}
          />
        </>
      )}

      {!loadingActions
        && !contextLoading
        && !status?.ccbAlreadyGenerated
        && currentContext?.id
        && !checkActions(['only-score'])
        && (
          <UserDrawer
            visible={!isBusinessRegistrationCompleted}
            onClose={() => {
              // setUserDrawerVisible(false);
              setIsBusinessRegistrationCompleted(true);
            }}
            onCompleted={() => {
              // setUserDrawerVisible(false);
              setIsBusinessRegistrationCompleted(true);
            }}
            alert="Para dar prosseguimento a sua solicitação, preencha e confirme os dados abaixo."
          />
        )}

      {handleOptinModal()}

    </div>
  );
};

export default Sider;
