/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  Form,
  Row,
  Col,
} from 'antd';
import {
  UploadCard,
} from 'components';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';

import { formatAndNotificateError } from 'utils';
import { useMutation } from '@apollo/client';
import { documentList } from '../../utils';
import { SAVE_BUSINESS } from '../../graphql';

const { Item } = Form;

const businessFile = {
  '204-6': 'Estatuto social',
  '205-6': 'Estatuto social',
  '206-2': 'Contrato social',
  '213-5': 'Certificado da condição do MEI ou Registro de requisição EI ou Declaração de incorporação da EIRELLI',
  '223-2': 'Contrato social',
  '224-0': 'Contrato social',
  '230-5': 'Declaração de incorporação EIRELI',
  '231-5': 'Declaração de incorporação EIRELI',
  '401-4': 'Requisição de registro EI',
};

const BadesulPronampeBusinessFileStep = ({
  files: {
    proofOfAddress,
    bylaws,
    majorityPartnerSelfie,
  } = {},
  additionalFiles: {
    taxReceiptOfBilling,
    annexV,
    eSocialPrint,
  } = {},
  additionalFiles,
  form,
  type,
  address,
  hideNotAnalyzedFiles,
  businessDocumentsRevised,
  setBusinessDocumentsRevised,
}) => {
  const { currentContext } = useBusinessLoanContext();

  const [saveBusiness, { loading: businessLoading }] = useMutation(SAVE_BUSINESS, {
    onCompleted: () => {
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const documents = useMemo(() => documentList.map((document) => ({
    ...document,
    document: additionalFiles[document.documentValue],
  })), [documentList, additionalFiles]);

  useEffect(() => {
    const obj = {};
    for (const document of documents) {

      if (document.document) {
        obj[document.documentName] = document.document.id;
      }
    }

    if (bylaws?.id) {
      obj.bylawsFileId = bylaws.id;
    }
    if (proofOfAddress?.id) {
      obj.proofOfAddressFileId = proofOfAddress.id;
    }
    if (majorityPartnerSelfie?.id) {
      obj.majorityPartnerSelfieFileId = majorityPartnerSelfie.id;
    }
    if (taxReceiptOfBilling?.id) {
      obj.badesulTaxReceiptOfBillingFileId = taxReceiptOfBilling.id;
    }
    if (annexV?.id) {
      obj.badesulAnnexVFileId = annexV.id;
    }
    if (eSocialPrint?.id) {
      obj.badesulESocialPrintFileId = eSocialPrint.id;
    }

    form.setFieldsValue(obj);
  }, documents);

  return (
    <>
      <Form
        className="form-footer"
        form={form}
        layout="vertical"
      >
        <Row gutter={[0, 20]}>
          {(bylaws?.analysis?.approved === false || !hideNotAnalyzedFiles) && (
            <Col span={24} className="bylaws-upload">
              <Item name="bylawsFileId" rules={[{ required: true, message: 'Por favor, insira o documento contratual' }]}>
                <UploadCard
                  id="bylaws-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={bylaws}
                  alert={(businessFile[type] === 'Contrato social' || businessFile[type] === 'Estatuto social')
                    && 'O documento precisa estar completo, com assinaturas e carimbos da junta comercial.'}
                  label={`${businessFile[type] || 'Documento contratual'} da empresa`}
                  actionButtonText={bylaws?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={bylaws?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ bylawsFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {(!hideNotAnalyzedFiles || address?.analysis?.approved === false) && (
            <Col span={24} className="proofOfAddressFileId">
              <Item name="proofOfAddressFileId" rules={[{ required: true, message: 'Por favor, insira o comprovante de endereço' }]}>
                <UploadCard
                  id="proof-of-address-upload"
                  accept="image/png,image/jpeg,application/pdf"
                  actualFile={proofOfAddress}
                  alert="Escolha uma das opções: Conta de luz, água, gás, telefone ou internet, com no máximo 90 dias, ou contrato de locação do imóvel. Enviar conta aberta. Não aceitamos somente a capa."
                  label="Comprovante de endereço"
                  actionButtonText={address?.analysis?.approved === false && 'Revisar'}
                  incompleteAnimation={address?.analysis?.approved === false}
                  onCompleted={async ({ id }) => {
                    await form.setFieldsValue({ proofOfAddressFileId: id });
                    setBusinessDocumentsRevised([...businessDocumentsRevised, id]);
                  }}
                />
              </Item>
            </Col>
          )}

          {
            documents.map(({
              document,
              documentName,
              label,
              alertMessage,
            }) => (!hideNotAnalyzedFiles || document?.analysis?.approved === false) && (
              <Col span={24} className={documentName}>
                <Item name={documentName} rules={[{ required: true, message: 'Por favor, insira o documento' }]}>
                  <UploadCard
                    id="rais-document-upload"
                    accept="image/png,image/jpeg,application/pdf"
                    actualFile={document}
                    alert={alertMessage}
                    label={label}
                    actionButtonText={document?.analysis?.approved === false && 'Revisar'}
                    incompleteAnimation={document?.analysis?.approved === false}
                    outsideLoading={businessLoading}
                    onCompleted={async ({ id }) => {
                      const obj = {};
                      obj[documentName] = id;

                      await form.setFieldsValue(obj);
                      setBusinessDocumentsRevised([...businessDocumentsRevised, id]);

                      const variables = {
                        id: currentContext.business.id,
                      };

                      variables[documentName] = id;
                      saveBusiness({
                        variables,
                      });
                    }}
                  />
                </Item>
              </Col>
            ))
          }

          <Col span={24}>
            <Item
              name="badesulTaxReceiptOfBillingFileId"
              rules={[
                {
                  required: true,
                  message: 'Insira a foto do documento solicitado',
                },
              ]}
            >
              <UploadCard
                id="badesul-tax-receipt-of-billing-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Comprovante fiscal de faturamento"
                actualFile={taxReceiptOfBilling}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulTaxReceiptOfBillingFileId: id });
                }}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="badesulAnnexVFileId">
              <UploadCard
                id="badesul-annex-v-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Anexo V assinado digitalmente (ICP-Brasil ou via GOV.BR)"
                alert="Caso seja optante do E-social"
                validateSignature
                actualFile={annexV}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulAnnexVFileId: id });
                }}
              />
            </Item>
          </Col>

          <Col span={24}>
            <Item name="badesulESocialPrintFileId">
              <UploadCard
                id="badesul-e-social-print-file-id"
                accept="image/png,image/jpeg,application/pdf"
                label="Captura de tela do cadastro do E-social"
                alert="Caso seja optante do E-social"
                actualFile={eSocialPrint}
                onCompleted={async ({ id }) => {
                  await form.setFieldsValue({ badesulESocialPrintFileId: id });
                }}
              />
            </Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default BadesulPronampeBusinessFileStep;
