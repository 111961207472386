/* eslint-disable no-param-reassign */
import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  notification,
  Steps,
} from 'antd';
import {
  Modal,
} from 'components/sdk';
import {
  badescPortfolios,
  badesulPortfolios,
  formatAndNotificateError,
} from 'utils';
import { useMutation } from '@apollo/client';
import { Images } from 'assets';
import { getWhiteLabelData } from 'services/graphql/cache';
import { isBrightColor } from 'utils/colors';
import { useBusinessLoanContext } from 'contexts/BusinessLoanContext';
import {
  TermsAndPoliciesOptIn,
  WhatsappOptIn,
} from './optIns';
import {
  SAVE_OPTIN,
} from './graphql';
import './styles.less';

const { LogoGyraWhite, LogoGyraDark } = Images;
const { Step } = Steps;

const OptInModal = ({
  onClose,
  logo,
  optIns,
}) => {
  const {
    currentContext,
  } = useBusinessLoanContext();

  const [stepsCount, setStepsCount] = useState(0);
  const [current, setCurrent] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const [ipLoading, setIpLoading] = useState(false);

  const whiteLabelData = getWhiteLabelData();
  const primaryColor = whiteLabelData?.colors?.primary;
  const isBright = primaryColor && isBrightColor(primaryColor);

  const [saveOptIn, { loading }] = useMutation(SAVE_OPTIN, {
    onCompleted: () => {
      if (current < stepsCount - 1) {
        setCurrent(current + 1);
        setConfirmation(false);
      } else {
        setCurrent(0);
        onClose();
      }
    },
    onError: (error) => formatAndNotificateError(error),
  });

  const handleSaveOptIn = ({ accepted, types, needConfirmation }) => {
    if (needConfirmation && !confirmation) {
      notification.error({
        message: 'Aceite o termo',
        description: 'Para aceitar o termo, clique para checar a opção na qual você concorda com o termo ',
        duration: 10,
      });
    } else {
      setIpLoading(true);
      fetch('https://api.ipify.org/?format=json')
        .then((response) => response.json())
        .then(({ ip }) => {
          saveOptIn({
            variables: {
              ip,
              accepted,
              types,
            },
          });
          setIpLoading(false);
        })
        .catch(() => { notification.error({ message: 'Não conseguimos localizar seu IP' }); });
    }
  };

  const portfolioName = currentContext?.loan?.portfolioName;
  const overwriteType = useMemo(() => {
    const isBadesc = badescPortfolios.includes(portfolioName);
    const isBadesul = badesulPortfolios.includes(portfolioName);

    if (isBadesc) {
      return 'BADESC';
    }

    if (isBadesul) {
      return 'BADESUL';
    }

    return undefined;
  }, [portfolioName]);

  const missingOptins = useMemo(() => {
    const {
      policyOptIn = false,
      registryOptIn = false,
      wppOptIn = false,
      badescOptIn = false,
      badesulOptin = false,
    } = optIns?.reduce((result, { type }) => {
      if (type === 'TERMS-AND-POLICIES') {
        result.policyOptIn = true;
      }
      if (type === 'RECEIVABLES-REGISTRY') {
        result.registryOptIn = true;
      }
      if (type === 'WHATSAPP') {
        result.wppOptIn = true;
      }

      if (type === 'BADESC') {
        result.badescOptIn = true;
      }
      if (type === 'BADESUL') {
        result.badesulOptin = true;
      }

      return result;
    }, {}) || {};

    const missingOptins = [];

    if (!policyOptIn) {
      missingOptins.push('TERMS-AND-POLICIES');
    }
    if (!registryOptIn) {
      missingOptins.push('RECEIVABLES-REGISTRY');
    }
    if (!wppOptIn) {
      missingOptins.push('WHATSAPP');
    }

    if (!badescOptIn && overwriteType === 'BADESC') {
      missingOptins.push('BADESC');
    }
    if (!badesulOptin && overwriteType === 'BADESUL') {
      missingOptins.push('BADESUL');
    }

    return missingOptins;
  }, [optIns, overwriteType]);

  const allSteps = [
    {
      optins: ['TERMS-AND-POLICIES', 'RECEIVABLES-REGISTRY', 'BADESC', 'BADESUL'],
      title: 'Termos de Uso e da Registradora, e Política de Privacidade',
      content: (
        <TermsAndPoliciesOptIn
          setConfirmation={setConfirmation}
          overwriteType={overwriteType}
          portfolioName={portfolioName}
        />
      ),
      okButton: {
        text: 'Aceitar',
        onClick: () => {
          const types = missingOptins.filter((optin) => {
            const isDefaultType = ['TERMS-AND-POLICIES', 'RECEIVABLES-REGISTRY'].includes(optin);
            if (isDefaultType) {
              return true;
            }

            if (overwriteType === 'BADESC' && optin === 'BADESC') {
              return true;
            }

            if (overwriteType === 'BADESUL' && optin === 'BADESUL') {
              return true;
            }

            return false;
          });

          handleSaveOptIn({
            accepted: true,
            types,
            needConfirmation: true,
          });
        },
      },
    },
    {
      optins: ['WHATSAPP'],
      title: 'Mais agilidade para acompanhar sua proposta?',
      content: (
        <WhatsappOptIn />
      ),
      okButton: {
        text: 'Quero receber atualizações!',
        onClick: () => handleSaveOptIn({ accepted: true, types: ['WHATSAPP'] }),
        id: 'whatsapp-opt-in-modal-confirm-button',
        'data-cy': 'opt-in-modal-confirm-button',
      },
      cancelButton: {
        text: 'Não quero receber atualizações',
        onClick: () => handleSaveOptIn({ accepted: false, types: ['WHATSAPP'] }),
        id: 'whatsapp-opt-in-modal-cancel-button',
        color: 'secondary',
      },
    },
  ];

  const steps = useMemo(() => {
    const steps = allSteps.filter(
      ({ optins }) => optins.filter(
        (optin) => {
          const has = missingOptins.some((missingOptin) => missingOptin === optin);
          return has;
        },
      )[0],
    );
    setStepsCount(steps.length);

    return steps;
  }, [optIns, confirmation]);

  return (
    <Modal
      style={{ whiteSpace: 'pre-line' }}
      title={(
        <img src={logo || isBright ? LogoGyraDark : LogoGyraWhite} alt="logo" className="opt-in-logo" />
      )}
      centered
      closable={false}
      className="opt-in-modal"
      visible={!!steps[0]}
      okButton={steps[current]?.okButton
        ? { ...steps[current]?.okButton, loading: ipLoading || loading }
        : undefined}
      cancelButton={steps[current]?.cancelButton
        ? { ...steps[current]?.cancelButton, disabled: ipLoading || loading }
        : undefined}
    >
      <Steps current={current}>
        {steps.map((item, index) => (
          <Step
            key={item.title}
            title={current === index ? item.title : ''}
            className={current !== index ? 'step-without-title' : ''}
          />
        ))}
      </Steps>

      <div className="steps-content">{steps[current]?.content}</div>
    </Modal>
  );
};

OptInModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  logo: PropTypes.string.isRequired,
  optIns: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
  })).isRequired,
};

export default memo(OptInModal);
