import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
} from 'antd';
import {
  CheckboxGif,
} from 'assets/images';
import './styles.less';
import {
  termsOfUseBadesc,
  termsOfUseBadescWoman,
  termsOfUseBadescGeneral,
} from 'utils';
import {
  Button,
  Modal,
} from 'components/sdk';

const TermsAndPoliciesOptIn = ({
  setConfirmation,
  overwriteType,
  portfolioName,
}) => {
  const [normalConfirmation, setNormalConfirmation] = useState(false);
  const [overwriteConfirmation, setOverwriteConfirmation] = useState(false);

  const [showBadescModal, setShowBadescModal] = useState(false);

  useEffect(() => {
    setConfirmation(
      overwriteType
        ? overwriteConfirmation && normalConfirmation
        : normalConfirmation,
    );
  }, [normalConfirmation, overwriteConfirmation]);

  const AllTermsOfUseBadesc = {
    'Pronampe Emergencial CP': termsOfUseBadesc,
    'Pronampe Emergencial SE': termsOfUseBadesc,
    'Pronampe Mulher': termsOfUseBadescWoman,
    'Pronampe Geral': termsOfUseBadescGeneral,
  };

  return (
    <div className="opt-in-content gyra-custom-scroll">
      <img src={CheckboxGif} alt="Checkbox Gif" />

      <Checkbox
        checked={normalConfirmation}
        id="opt-in-checkbox"
        onChange={({ target: { checked } }) => setNormalConfirmation(checked)}
      >
        <p>
          Li e aceito os
          {' '}
          <a className="secondary-text" href="https://faq.gyramais.com.br/termos-de-uso/" target="_blank" rel="noreferrer">
            Termos e Condições do Solicitante
          </a>
          {' '}
          e da
          {' '}
          <a className="secondary-text" href="https://faq.gyramais.com.br/termos-de-consulta-registradoras/" target="_blank" rel="noreferrer">
            Registradora
          </a>
          {' '}
          e as
          {' '}
          <a className="secondary-text" href="https://faq.gyramais.com.br/politica-de-privacidade/" target="_blank" rel="noreferrer">
            Políticas de Privacidade.
          </a>
        </p>
      </Checkbox>

      {overwriteType === 'BADESC' && (
        <Checkbox
          checked={overwriteConfirmation}
          id="opt-in-checkbox"
          onChange={({ target: { checked } }) => setOverwriteConfirmation(checked)}
        >
          <p>
            Li e aceito os
            {' '}
            <Button
              text
              className="badesc-modal-button secondary-text"
              onClick={() => {
                setShowBadescModal(true);
              }}
            >
              Termos do Badesc
            </Button>
          </p>
        </Checkbox>
      )}

      {overwriteType === 'BADESUL' && (
        <Checkbox
          checked={overwriteConfirmation}
          id="opt-in-checkbox"
          onChange={({ target: { checked } }) => setOverwriteConfirmation(checked)}
        >
          <p>
            Li e aceito os
            {' '}
            <Button
              text
              className="badesc-modal-button secondary-text"
              onClick={() => {
                window.open('https://faq.gyramais.com.br/badesul/');
              }}
            >
              Termos do Badesul
            </Button>
          </p>
        </Checkbox>
      )}

      <Modal
        title="Termos de uso"
        visible={showBadescModal}
        onCancel={() => {
          setShowBadescModal(false);
        }}
        footer={(
          <Button onClick={() => setShowBadescModal(false)}>
            Fechar
          </Button>
        )}
      >
        <div className="badesc-terms-container">
          {AllTermsOfUseBadesc[portfolioName]}
        </div>
      </Modal>
    </div>
  );
};

TermsAndPoliciesOptIn.propTypes = {
  setConfirmation: PropTypes.func.isRequired,
  overwriteType: PropTypes.string.isRequired,
  portfolioName: PropTypes.string.isRequired,
};

export default memo(TermsAndPoliciesOptIn);
