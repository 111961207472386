/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'components/gyramais';
import { formatString } from 'utils';

import './styles.less';

const typeObj = {
  PEP: {
    title: 'DECLARAÇÃO SOBRE CONDIÇÃO DE PESSOA EXPOSTA POLITICAMENTE',
    text: (
      <>
        <b>
          não exerce, nem exerceu nos últimos cinco anos, função pública relevante que o(a) caracterize como pessoa politicamente exposta
        </b>
        , conforme definido pela Resolução nº 29/2021 do Conselho de Controle de Atividades Financeiras (COAF).
      </>
    ),
  },
  badesulRelationship: {
    title: 'DECLARAÇÃO DE AUSÊNCIA DE RELACIONAMENTO COM O BADESUL E O GOVERNO DO ESTADO DO RIO GRANDE DO SUL',
    text: (
      <>
        <b>não mantém, nem manteve qualquer relacionamento comercial, financeiro, contratual ou de outra natureza com o Badesul Desenvolvimento S.A. – Agência de Fomento/RS, bem como com órgãos ou entidades vinculadas ao Governo do Estado do Rio Grande do Sul</b>
        , que possa caracterizar conflito de interesses ou gerar impedimentos legais ou éticos.
      </>
    ),
  },
};

/**
 *
 * @param {{
 *  user:{
 *  fullName:string,
 *  cpf:string
 * }
 * business:{
 *  legalName:string,
 *  name: string
 *  }
 * address:{
 *   street: string
 *   number: string
 *   district: string
 *   postalCode: string
 *   city: string
 *   state: string
 * }
 *  type: "PEP" | "badesulRelationship"
 * }} param0
 * @returns
 */
const SelfDeclarationCard = ({
  user: {
    fullName,
    cpf,
  } = {},
  business: {
    legalName,
    name,
  },
  type,
  address,
}) => (
  <>
    {/* {Boolean(alert) && (
      <Alert
        className="terms-card-alert"
        message={alert}
      />
    )} */}
    <Card className="self-declarition-card">
      <div className="terms-card-title">
        {typeObj[type].title}
      </div>
      <div className="terms-card-body">
        Declaro para os devidos fins e efeitos legais que
        <b className="highlight-text">{fullName}</b>
        , sócio(a) da empresa
        <b className="highlight-text">{name || legalName}</b>
        , inscrito(a) no CPF sob o número
        <b className="highlight-text">{(cpf && formatString(cpf, 'cpf')) || '-'}</b>
        , residente e domicilado(a)
        à
        <b className="highlight-text">{address?.street}</b>
        , nº
        <b className="highlight-text">{address?.number}</b>
        -
        <b className="highlight-text">{address?.district}</b>
        , CEP
        <b className="highlight-text">{(address?.postalCode && formatString(address.postalCode, 'cep')) || '-'}</b>
        , na cidade de
        <b className="highlight-text">{address?.city}</b>
        -
        <b className="highlight-text">{address?.state}</b>
        ,

        {typeObj[type].text}
      </div>
    </Card>
  </>
);

export default SelfDeclarationCard;
